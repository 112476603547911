
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import ExporoTooltip from '@/components/_common/Tooltip.vue';

@Component({
  name: 'stepper',
  components: {
    ExporoTooltip,
  },
})
export default class AppHeader extends Vue {
  @Prop({ default: () => [] }) steps: string[];
  @Prop({ default: 0 }) activeStep: number;

  get percent(){
    return  100 / (this.steps.length - 1 ) * this.activeStep  + '%';
  }
}

