var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{staticClass:"message-modal",attrs:{"active":_vm.modalOpen,"name":"message","onClose":_vm.onClose},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a',{staticClass:"btn primary",attrs:{"title":_vm.$t('dashboard.back_to_dashboard')},on:{"click":function($event){$event.preventDefault();return _vm.goHome.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.back_to_dashboard")))])])]},proxy:true}])},[(!_vm.error)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.message)}}):_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t("error.generic.title"))+" ")]),(_vm.message)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.message)}}):_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t('error.generic.text', {
          link: `<a target='_blank' href='${_vm.$t('error.generic.linkPath')}'>
            ${_vm.$t('error.generic.linkTitle')}
            </a>`,
        })
      )}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }