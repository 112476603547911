
import { Component, Prop, Vue } from 'vue-property-decorator';

import Modal from '@/components/_common/Modal.vue';
import { Getter } from 'vuex-class';

@Component({
  name: 'create-wallet-modal',
  components: {
    Modal,
  },
})
export default class MessageModal extends Vue {
  @Prop({ default: true })
  private modalOpen: boolean;

  @Prop({ default: true })
  private message: string;

  @Prop({ default: false })
  private error: boolean;

  @Prop({ default: null })
  private onClose: any;

  @Getter('appState/redirectUrl') redirectUrl?: string;

  beforeMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  get goToCockpitUrl() {
    return this.redirectUrl || process.env.VUE_APP_EXPORO_COCKPIT_URL;
  }

  private goHome() {
    this.$router.push('/');
  }

}
