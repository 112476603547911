
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'field-set',
})
export default class Fieldset extends Vue {
  @Prop({ required: true })
  legend: string;
}
